.sidebar {
  height: 100%;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: -14%;
  top: 0;
  backdrop-filter: blur(20px);
  z-index: 4;
  transform: translateX(100%);
  animation: sidebar 1s forwards ease-out;
  &__container {
    width: 90%;
    .nav__item.close {
      display: flex;
      justify-content: flex-end;
      margin-right: 75px;
      margin-bottom: 0;
      padding: 20px 25px 10px 25px; }
    .nav__item.close::before {
      content: "+";
      color: #006EBC;
      font-size: 40px;
      transform: rotate(45deg); }
    .nav__item {
      margin-bottom: 25px;
      a {
        text-decoration: none;
        color: #006EBC;
        font-size: 16px;
        padding-left: 25px; }
      .collaborationRef:hover {
        border-left: 2px solid #006EBC;
        padding-left: 25px; }
      .catalogRef:hover {
        border-left: 2px solid #006EBC;
        padding-left: 25px; }
      .newsRef:hover {
        border-left: 2px solid #006EBC;
        padding-left: 25px; }
      .contactsRef:hover {
        border-left: 2px solid #006EBC;
        padding-left: 25px; } } } }


@keyframes sidebar {
  100% {
    transform: translateX(0); } }

@media only screen and (max-width : 650px) {
  .sidebar__container .nav__item.close {
    margin-right: 40px; } }

@media only screen and (max-width : 425px) {
  .sidebar {
    width: 70%; } }

@media only screen and (max-width : 375px) {
  .sidebar {
    &__container {
      .nav__item.close {
        padding: 20px 10px 10px 10px; } } } }
