.member-component {
  &__container {
    &_img {
      width: 100%;
      max-width: 326px; }
    &_img:hover + .member-component__container_tooltip {
      display: block;
      transition: 0.3s; }
    &_tooltip {
      position: absolute;
      top: -41px;
      margin-top: 41px;
      margin-left: 0;
      width: 100%;
      height: 73%;
      background-color: #006ebc;
      display: none;
      border-radius: 15px;
      &__text {
        margin-top: 20px;
        color: #FFFFFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        padding: 0 15px; } }
    &_last-name {
      color: #000;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      padding: 13px 0 0 0;
      margin: 0; }
    &_name {
      color: #000;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      padding: 0;
      margin: 0; }
    &_position {
      color: #464646;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      padding: 13px 0 0 0;
      margin: 0; } } }

@media only screen and (max-width: 650px) {
  .member-component {
    &__container {
      &_tooltip {
        height: 76%;
        &__text {
          font-size: 18px; } }
      &_last-name {
        font-size: 18px; }
      &_name {
        font-size: 18px; }
      &_position {
        font-size: 18px; } } } }
