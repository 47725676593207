.detail-component {
  width: 100%;
  margin-top: 7%;
  &__container {
    display: flex;
    flex-direction: row;
    &_left {
      width: 50%;
      &__title {
        padding: 0;
        margin: 0;
        color: #F70;
        font-size: 20px;
        font-weight: bold;
        max-width: 600px; }
      &__subtitle {
        padding: 0;
        margin: 0 0 22px 0;
        color: #000;
        font-size: 20px;
        max-width: 600px; } }
    &_right {
      width: 50%;
      &__text {
        padding: 0;
        margin: 0;
        color: #000;
        font-size: 20px;
        &_strong {
          color: #F70;
          font-size: 20px;
          font-weight: bold; } } } } }


@media only screen and (max-width: 600px) {
  .detail-component {
    &__container {
      display: flex;
      flex-direction: column;
      &_left {
        width: 100%;
        &__title {
          font-size: 18px; }
        &__subtitle {
          font-size: 18px; } }
      &_right {
        width: 100%;
        &__text {
          font-size: 18px; }
        &__text_strong {
          font-size: 18px; }
        &__subtitle {
          font-size: 18px; } } } } }
