.teamSwiper {
  width: 100%;
  padding-top: 50px;
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: max-content;
    .swiper-slide img {
      display: block;
      width: 100%; } } }

