.App {
  background-color: white;
  width: 86%;
  margin: 0 auto;
  padding: 0;
  outline: none;
}
.App-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .App {
    width: 95%;
  }
}
p {
  padding: 0;
  margin: 0;
}