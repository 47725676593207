.transports-component {
  width: 100%;
  &__text {
    padding-left: 45px;
    margin: 3% auto;
    &_subtitle {
      color: #000;
      font-size: 24px;
      font-weight: 400;
      padding: 0;
      margin: 0; } }
  &__container {
    background-color: #F2F2F2;
    border-radius: 30px;
    padding: 40px;
    &_transports {
      display: grid;
      grid-template-columns: 50% 50%;
      &__buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        &_button {
          display: flex;
          align-items: center;
          button {
            margin-right: 20px;
            padding: 0;
            background: transparent;
            border: none;
            color: #000000;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase; } }
        &_button.active {
          button {
            color: #ffff;
            background: #006EBC;
            padding: 10px;
            border-radius: 10px; } } } } } }


.swiper {
  width: 90%;
  height: 100%; }
.swiper-slide {
  text-align: left;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
.swiper-pagination {
  text-align: left!important; }
.swiper-pagination-bullet {
  width: 60px!important;
  height: 7px!important;
  border-radius: 10px!important;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2); }

@media only screen and (max-width: 1024px) {
  .transports-component__title {
    margin: 0 0 35px 0; }
  .title-transport {
    margin: 0 0 30px 0; }
  .swiper {
    width: 100%;
    height: 100%; } }

@media only screen and (max-width: 900px) {
  .transports-component__container {
    &_transports {
      grid-template-columns: 100%; } } }

@media only screen and (max-width: 768px) {
  .swiper-slide {
    justify-content: center; }
  .transports-component {
    padding: 0;
    margin: 50px 0;
    &__container_images {
      width: 100%; } } }



@media only screen and (max-width: 600px) {
  .transports-component {
    padding: 0;
    margin: 50px 0;

    &__text {
      padding: 0 0 0 25px;
      &_subtitle {
        font-size: 18px; } }
    &__container {
      &_images {
        width: 100%; }
      &_transports__buttons_button button {
        font-size: 18px; } } }
  .swiper-pagination-bullet {
    width: 40px!important; } }

@media only screen and (max-width: 475px) {
  .transports-component__container {
    padding: 40px 20px; } }
