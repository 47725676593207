nav {
  display: flex;
  width: 86%;
  margin: 0 auto;
  z-index: 5;
  position: relative;
  background-color: #F2F2F2;
  border-radius: 70px;
  height: 18%;
  max-height: 150px;
  .nav__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 22px;
    width: 100%;
    &_logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      &_subtitle {
        font-family: Onest,sans-serif;
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        color: rgba(16, 16, 16, 1);
        width: 60%; }
      a {
        margin-right: 7%;
        img {
          max-width: 103px;
          width: 100%; } } }
    &_items {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-self: center;
      align-items: center;
      justify-content: center;
      width: 35%;
      &__item {
        margin-left: 30%;
        a {
          font-family: 'Onest', sans-serif;
          font-weight: 500;
          font-size: 20px;
          color: rgba(63, 62, 62, 1);
          text-align: right;
          text-decoration: none;
          margin: 0;
          display: block; } }
      &__item:first-child {
        margin-left: 0;
        a {
          font-family: 'Onest', sans-serif;
          font-weight: 500;
          font-size: 20px;
          color: rgba(63, 62, 62, 1);
          text-align: right;
          text-decoration: none;
          margin: 0;
          display: block; } } }
    &_item-phone {
      width: 20%;
      text-align: right;
      &__phone {
        color: rgba(0, 110, 188, 1);
        font-family: Onest,sans-serif;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none; } } } }



@media only screen and (max-width: 1160px) {
  nav .nav__container_item-phone__phone {
      font-size: 20px; } }
