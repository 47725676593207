.why-us-component {
  width: 95%;
  margin: 50px 0 0 0;
  &__container {
    &_title {
      color: rgba(0, 110, 188, 1);
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 35px; }
    &_plash {
      display: grid;
      grid-template-columns: 32% 32% 32%;
      gap: 30px; } } }
.plash {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 40%;
  min-width: 372px;
  max-width: 372px;
  height: 594px;
  border-radius: 30px;
  padding: 60px 0 20px 45px;
  box-sizing: border-box;
  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 10%;
    margin-right: 10px; }
  //&__img
 }  //  margin-right: -10px
.plash .plash0 {}

.swiper-slide {
  width: 30%; }


@media only screen and (min-width: 1920px) {
  .plash {
    &__title {
      font-size: 24px; } }
  .swiper-slide {
    width: 20%; } }

@media only screen and (max-width: 1440px) {
  .swiper-slide {
    width: 35%; } }

@media only screen and (max-width: 1150px) {
  .swiper-slide {
    width: 40%; } }

@media only screen and (max-width: 1024px) {
  .why-us-component {
    &__container {
      &_plash {
        grid-template-columns: 48% 48%;
        gap: 25px; } } }
  .swiper-slide {
    width: 50%; } }

@media only screen and (max-width: 768px) {
  .swiper-slide {
    width: 70%; } }

@media only screen and (max-width: 650px) {
  .why-us-component {
    &__container {
      &_plash {
        grid-template-columns: 100%;
        gap: 0; }
      &_title {
        font-size: 36px; } } }
  .plash__img img {
    width: 100%; }
  .plash {
    margin-bottom: 20px;
    width: 93%;
    min-width: min-content;
    max-width: max-content;
    &__title {
      font-size: 18px; } }
  .swiper-slide {
    width: 100%; } }

@media only screen and (max-width: 500px) {
  .plash {
    .plash__img {
      margin-right: 0; } }
  .why-us-component {
    width: 100%; } }
