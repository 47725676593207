.contacts-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
  gap: 15px;
  &__contact {
    //width: 50%
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    &_title {
      display: flex;
      flex-direction: column;
      &__info {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        max-width: 485px;
        padding: 20px 0 20px 40px; } }
    &_ref {
      display: flex;
      flex-direction: row;
      gap: 15px;
      padding-top: 15px;
      a {
        text-decoration: none;
        display: block; } } }
  &__map {
    width: 55%;
    img {
      width: 100%; } } }

@media only screen and (max-width: 1024px) {
  .contacts-component {
    margin-top: 50px;
    gap: 25px;
    &__contact {
      &_ref {
        display: flex;
        flex-direction: row; } } } }

@media only screen and (max-width: 800px) {
  .contacts-component {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &__map {
      width: 100%; } } }


@media only screen and (max-width: 600px) {
  .contacts-component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 30px;
    &__contact {
      &_title {
        &__info {
          padding-left: 25px;
          font-size: 18px; } }
      &_ref {
        display: flex;
        flex-direction: column; } } } }
