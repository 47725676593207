footer {
  margin-top: 10%;
  .footer__container {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    &_logo {
      width: 100%;
      max-width: 103px; }
    &_items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      max-width: 397px;
      width: 100%;
      box-sizing: border-box;
      &__item a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        color: rgba(63, 62, 62, 1); } } }
  .footer__politic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 50px 0;
    &_text {
      max-width: 550px; }
    a {
      color: #FF7700; } } }

@media only screen and (max-width: 650px) {
  footer {
    margin-top: 50px;
    .footer__container {
      border-bottom: none;
      width: 100%;
      padding: 15px 0;
      margin: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      &_items {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        gap: 10px;
        &__item a {
          font-size: 14px; } } }
    .footer__politic {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      padding: 0 0 50px 0; } } }
