.images-transport {
  object-position: center center;
  width: 100%;
  max-width: 515px;
  margin-bottom: 30px; }

@media only screen and (max-width: 1024px) {
  .images-transport {
    padding-right: 10px;
    box-sizing: border-box;
    margin-bottom: 0; } }

@media only screen and (max-width: 900px) {
  .images-transport {
    max-width: none;
    margin-bottom: 30px; } }
