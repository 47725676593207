.title-component {
  background-color: rgba(242, 242, 242, 1);
  width: max-content;
  border-radius: 50px;
  font-size: 54px;
  color: rgba(0, 110, 188, 1);
  font-weight: 700;
  padding: 10px 40px;
  margin-top: 125px; }

@media only screen and (max-width: 1024px) {
  .title-component {
    font-size: 36px; } }

@media only screen and (max-width: 600px) {
  .title-component {
    padding: 10px 25px;
    margin-top: 60px; } }
