form {
  display: flex;
  flex-direction: column;
  .form-submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .agree {
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      padding: 15px 0 15px 0;
      a {
        color: rgba(255, 255, 255, 1);
        font-weight: bold; } }
    input:hover {
      background-color: rgba(0, 110, 188, 1);
      color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(255, 255, 255, 1);
      transition: 0.5s; }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 177px;
      height: 45px;
      background-color: rgba(255, 255, 255, 1);
      border: none;
      font-weight: 700;
      font-size: 15px;
      color: rgba(0, 110, 188, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px; }
    p {
      font-size: 16px;
      color: #3590D6;
      padding: 10px 0;
      text-align: left;
      width: 100%;
      max-width: 390px; } }
  .form-items {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: repeat(3, auto);
    gap: 10px 15px;
    .item1 {
      grid-column: 1 / 2;
      grid-row: 1 / 2; }
    .item2 {
      grid-column: 1 / 2;
      grid-row: 2 / 3; }
    .item3 {
      grid-column: 1 / 2;
      grid-row: 3 / 4; }
    .item4 {
      grid-column: 2 / 3;
      grid-row: 1 / 2; }
    .item5 {
      grid-column: 2 / 3;
      grid-row: 2 / 3; }
    .item6 {
      grid-column: 2 / 3;
      grid-row: 3 / 4; }
    &__item {
      display: block;
      .form-input {
        width: 100%;
        height: 50px;
        background: white;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 1);
        color: #757575;
        display: block;
        font-size: 14px;
        padding-left: 20px;
        box-sizing: border-box; }
      .form-input::placeholder {
        color: rgba(223, 223, 223, 1); } } } }




@media only screen and (min-width: 1921px) {
  form .form-items {
    gap: 20px; }
  form .form-items__item .form-input {
    height: 75px;
    font-size: 18px; }
  form .form-submit .agree {
    font-size: 15px; }
  form .form-submit input {
    width: 250px;
    height: 65px;
    font-size: 22px;
    margin-top: 20px; } }

@media only screen and (max-width: 1024px) {
  form {
    .form-items {
      grid-template-columns: 98%;
      grid-template-rows: repeat(6, auto);
      gap: 0;
      &__item {
        margin-bottom: 10px; }
      .item1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2; }
      .item2 {
        grid-column: 1 / 2;
        grid-row: 2 / 3; }
      .item3 {
        grid-column: 1 / 2;
        grid-row: 3 / 4; }
      .item4 {
        grid-column: 1 / 2;
        grid-row: 5 / 6; }
      .item5 {
        grid-column: 1 / 2;
        grid-row: 6 / 7; }
      .item6 {
        grid-column: 1 / 2;
        grid-row: 7 / 8; } } } }


