header {
  height: 100vh;
  width: 100%;
  background-image: url("../../media/img/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2% 0;
  box-sizing: border-box;
  .header-component {
    width: 86%;
    margin: 2% auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: space-between;
    &__form {
      border-radius: 30px;
      background-color: rgba(157, 162, 180, 0.37);
      width: 60%;
      height: auto;
      padding: 50px 45px;
      box-sizing: border-box;
      &_h1 {
        font-family: 'Onest', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        color: #FFFFFF;
        text-align: left;
        line-height: 38px;
        margin: 0;
        padding-bottom: 10px; }
      &_p {
        font-family: 'Onest', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-align: left;
        padding: 0 0 10px 0;
        margin: 0; } } } }


@media only screen and (min-width: 1440px) {
  header .header-component__form {
    width: 80%; } }

@media only screen and (max-width: 1400px) {
  header .header-component__form h1 {
    font-size: 36px;
    line-height: 38px; } }

@media only screen and (max-width: 1120px) {
  header .header-component__form {
    padding: 25px 45px;
    width: 100%; } }

@media only screen and (max-width: 1024px) {
  header {
    height: max-content; }
  header .header-component__form h1 {
    font-size: 32px;
    line-height: 34px; }
  header .header-component {
    margin: 43px auto 0 auto;
    &__form {
      padding: 30px 25px; } } }


@media only screen and (max-width: 800px) {
  header .header-component__form h1 {
    font-size: 32px;
    line-height: 34px; }
  header .header-component, header .header-component {
    flex-direction: column; }
  header .header-component {
    margin: 43px auto 0 auto;
    &__form {
      width: 100%;
      padding: 30px 25px; }
    &__img {
      width: 100%; } } }

@media only screen and (max-width: 600px) {
  header .header-component__form h1 {
    font-size: 26px;
    line-height: 32px; }
  header .header-component {
    width: 95%; } }

@media only screen and (max-width: 425px) {
  header .header-component__form h1 {
    font-size: 18px;
    line-height: 24px; }
  header .header-component__form_p {
    font-size: 16px; }
  header .header-component__form {
    padding: 20px 15px; } }
