html, body {
  margin: 0;
  padding: 0;
  outline: none;
  overflow-x: hidden;
  font-family: 'Onest', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-moz-selection{
  background-color:#EE842C;
}

::selection{
  background-color:#EE842C;
}